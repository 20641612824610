<template>
  <v-container
    tag="section"
    fluid
  >
    <v-overlay
      v-if="loading.statsGeneral || loading.statsTags"
      color="white"
      opacity="1"
      absolute
    >
      <v-progress-circular
        :size="64"
        color="primary"
        indeterminate
      />
    </v-overlay>
    <v-row
      class="px-4"
      dense
    >
      <v-col
        :cols="12"
        :xl="true"
        :lg="4"
        :md="6"
        :sm="12"
      >
        <base-material-stats-card
          :disabled="loading.statsGeneral"
          color="primary"
          icon="mdi-calendar"
          title="Total Events"
          :value="`${statsGeneral.events_count}`"
        />
      </v-col>
      <v-col
        :cols="12"
        :xl="true"
        :lg="4"
        :md="6"
        :sm="12"
      >
        <base-material-stats-card
          :disabled="loading.statsGeneral"
          color="primary"
          icon="mdi-card-bulleted"
          title="Total Projects"
          :value="`${statsGeneral.projects_count}`"
        />
      </v-col>
      <v-col
        :cols="12"
        :xl="true"
        :lg="4"
        :md="6"
        :sm="12"
      >
        <base-material-stats-card
          :disabled="loading.statsGeneral"
          color="primary"
          icon="mdi-account"
          title="Total Participants"
          :value="`${statsGeneral.active_participants_count}`"
        />
      </v-col>
      <v-col
        :cols="12"
        :xl="true"
        :lg="4"
        :md="6"
        :sm="12"
      >
        <base-material-stats-card
          :disabled="loading.statsGeneral"
          color="primary"
          icon="mdi-tag"
          title="Active Tags"
          :value="`${statsGeneral.active_tags_count}`"
        />
      </v-col>
      <v-col
        :cols="12"
        :xl="true"
        :lg="4"
        :md="6"
        :sm="12"
      >
        <base-material-stats-card
          :disabled="loading.statsGeneral"
          color="primary"
          icon="mdi-thumb-up"
          title="Active Likes"
          :value="`${statsGeneral.active_likes_count}`"
        />
      </v-col>
    </v-row>
    <v-row
      class="pa-4"
      dense
    >
      <v-col :cols="12">
        <v-expansion-panels
          :value="0"
          flat
        >
          <v-expansion-panel
            :disabled="loading.statsGeneral"
          >
            <v-card outlined>
              <v-expansion-panel-header>
                Tag Cloud
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pa-4">
                <vue-word-cloud
                  :style="{ height: '480px' }"
                  :words="computedTagsForCloud"
                  :spacing="0.15"
                  :color="([word, weight]) => weight === 0 ? '#eee' : $randomColor({ luminosity: 'bright', seed: word })"
                  font-family="Roboto"
                >
                  <template v-slot="{ text, weight }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <div
                          style="cursor: default;"
                          v-on="on"
                        >
                          {{ text }}
                        </div>
                      </template>
                      <span>The tag "{{ text }}" was used {{ weight }} {{ 'time' | pluralize(weight) }}</span>
                    </v-tooltip>
                  </template>
                </vue-word-cloud>
              </v-expansion-panel-content>
            </v-card>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getStatsGeneral, getStatsTags } from '@/api'
export default {
  name: 'DashboardDashboard',
  data: () => ({
    statsGeneral: {
      events_count: 0,
      projects_count: 0,
      active_participants_count: 0,
      active_tags_count: 0,
      active_likes_count: 0
    },
    statsTags: null,
    loading: {
      statsGeneral: null,
      statsTags: null
    }
  }),
  computed: {
    computedTagsForCloud () {
      if (!this.statsTags) return []
      return this.statsTags.map(tag => ([
        tag.display_name,
        tag.count
      ]))
    }
  },
  created () {
    this.refreshStatsGeneral()
    this.refreshStatsTags()
  },
  methods: {
    async refreshStatsGeneral () {
      this.loading.statsGeneral = true
      try {
        this.statsGeneral = (await getStatsGeneral())[0]
      } catch (error) {
        console.error(error)
      }
      this.loading.statsGeneral = false
    },
    async refreshStatsTags () {
      this.loading.statsTags = true
      try {
        this.statsTags = await getStatsTags()
      } catch (error) {
        console.error(error)
      }
      this.loading.statsTags = false
    }
  }
}
</script>
